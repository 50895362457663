import { NgModule } from '@angular/core';
import { NgxNavigationWithDataComponent } from './ngx-navigation-with-data.component';

@NgModule({
  imports: [
  ],
  declarations: [NgxNavigationWithDataComponent],
  exports: [NgxNavigationWithDataComponent]
})
export class NgxNavigationWithDataModule { }
