import { Component, OnInit } from '@angular/core';
import { NgxNavigationWithDataService } from './ngx-navigation-with-data.service';

@Component({
  selector: 'lib-ngx-navigation-with-data',
  template: `
    <p>
      ngx-navigation-with-data works!
      Thanks https://www.npmjs.com/~yrun
    </p>
  `,
  styles: []
})
export class NgxNavigationWithDataComponent implements OnInit {
  constructor(public navigation: NgxNavigationWithDataService) { }

  ngOnInit() {
  }
  /**
   * data() method
   * returns the whole data object of previouse page if any else undefined
   * For getting the data of previous pagem eg: 'name'
   */
  public get data() {
    return this.navigation.data;
  }

  /**
 * get(keyName:String) method
 * returns the key value of data object of previouse page if any else undefined
 * For getting the data of previous pagem eg: 'name'
 */
  public get(value) {
    return this.navigation.get(value);
  }
  
  /**
   * navigate(page:String, data:Object) method
   * params : 1. Name of route path at app-routing.module.ts
   * params : 2. Data to be send to next page eg : {name:"Virendra", lastName:"Yadav"}
   */
  
  public navigate(page, data={}) {
    this.navigation.navigate(page, data);
  }

}
