import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NgxNavigationWithDataService {
  public data = {};
  public pages = [];

  constructor(public router:Router) { }
  public get(value) {
    return this.data[value];
  }

  public navigate(page, data={}) {
    this.data = data;
    this.pages.push({page, data});
    this.router.navigate([page]);
  }

}
